



























































import Vue from 'vue'
import Component from 'vue-class-component'
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniArrowButton from "@/components/buttons/SygniArrowButton.vue";
import draggable from 'vuedraggable';
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';
import { Prop, Watch } from 'vue-property-decorator';
import { AutentiParticipant } from '../store/types';
import _ from 'lodash';

@Component({
  components: { SygniArrowButton, SygniRoundedButton, UserPresentation, ExpandCollapse, simplebar, draggable }
})
export default class ParticipantsTable extends Vue {
  @Prop({ default: false }) participationPriority: boolean;
  @Prop({ default: false }) isDisabled: boolean;
  @Prop({ default: [] }) items: AutentiParticipant[];

  tableItems: AutentiParticipant[] = []

  get tableFields() {
    let tableFields = [
      { key: 'name', label: 'Name', class: ['text-left left name'] },
      { key: 'email', label: 'Email', class: 'text-left email' },
      { key: 'role', label: 'Role', class: 'text-left role' },
      { key: 'signatureType', label: 'Signature Type', class: ['text-left signature-type'] },
    ]
    
    if (!this.isDisabled) {
      tableFields.push({ key: 'actions', label: 'Actions', class: ['actions'] })
    }

    if (this.participationPriority) {
      const participationFields = [
        { key: 'priority', label: 'Priority', class: ['text-left priority'] }
      ]

      if (!this.isDisabled) {
        participationFields.unshift({ key: 'handle', label: '', class: ['handles'] })
      }

      return [...participationFields, ...tableFields]
    }

    return tableFields
  }

  hasPriority(role: string) {
    return role?.toUpperCase() === 'SIGNER' || role?.toUpperCase() === 'APPROVER'
  }

  getSignatureTypeLabel(value: string) {
    switch (value) {
      case 'SIGNATURE_PROVIDER-SIGNATURE_TYPE:BASIC':
        return 'E-signature Autenti'
      case 'SIGNATURE_PROVIDER-SIGNATURE_TYPE:ADVANCED':
        return 'Advanced E-signature Autenti'
      case 'SIGNATURE_PROVIDER-SIGNATURE_TYPE:QUALIFIED':
        return 'Qualified signature'
      default:
        return value
    }
  }

  formatField(value: string, key: string) {
    switch (key) {
      case 'signatureType':
        return this.getSignatureTypeLabel(value)
      case 'role':
        value = this.$options.filters.snakeCaseToTitleCase(value?.toUpperCase());
        value = this.$options.filters.capitalizeFirstLetter(value);

        return value
      default:
        return value
    }
  }

  getTooltipMessage(message: string) {
    return message?.length <= 35 ? '' : message
  }

  loadItems() {
    this.tableItems = _.cloneDeep(this.items)
  }

  mounted() {
    this.loadItems();
  }

  @Watch('items', { deep: true, immediate: true }) onItemsChange() {
    this.$nextTick(() => {
      this.loadItems();
    })
  }
}

